import styled from "styled-components";

import media from "constants/media";

export const ShipmentContainer = styled.div`
    display: flex;
    align-items: center;
    max-width: 245px;
    margin: 0 auto;
    padding: 10px 0 25px;

    ${media.mdUp} {
        flex-wrap: wrap;
        width: 50%;
        max-width: calc(50% - 10px);
        padding: 0;
    }

    ${media.lgUp} {
        flex: 1 1 auto;
        max-width: 250px;
        margin: 0;
        padding: 0;
    }

    ${media.xlUp} {
        max-width: calc(25% - 15px);
    }
`;

export const Title = styled.h2`
    display: none;
    width: 100%;
    margin: 0 0 20px;

    color: var(--color-white);
    font-weight: var(--font-weight-medium);
    font-size: 16px;
    font-family: var(--font-family-secondary);
    line-height: 25px;

    ${media.mdUp} {
        display: block;
    }
`;

export const Logo = styled.img`
    width: 50px;
    height: 50px;

    ${media.mdUp} {
        width: 40px;
        height: 40px;
        margin: 0 0 0 15px;
    }
`;

export const Text = styled.p`
    max-width: calc(100% - 40px);
    margin: 0 0 0 25px;

    color: var(--color-white);
    font-size: 14px;
    line-height: 30px;

    ${media.mdUp} {
        max-width: 170px;
        margin: 0 0 0 25px;
    }
`;
