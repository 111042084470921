import styled from "styled-components";

import media, { sizes } from "constants/media";

import Container from "components/ui/container/Container";

export const StyledFooter = styled.footer`
    position: relative;

    display: flex;
    flex-direction: column;
    gap: 20px 0;
    width: 100%;
    margin: 100px 0 0;

    background-color: var(--color-primary);

    ${media.lgUp} {
        gap: 35px 0;
        padding: 40px 0 45px;
    }

    :after {
        position: absolute;
        top: -98px;
        left: 0;
        z-index: -1;

        width: 100%;
        height: 98px;

        background: url("/images/shapes/footer-bg-desktop.svg") center
            top/1932px 98px repeat-x;

        content: "";
    }
`;

export const Row = styled(Container)`
    position: relative;

    display: flex;
    flex-direction: column;
    gap: 0 20px;
    justify-content: flex-end;

    :first-of-type {
        flex-flow: row wrap;
        padding-bottom: 30px;

        :after {
            position: absolute;
            bottom: 0;
            left: calc(((100vw - ${sizes.md}px) / 2) * -1);

            width: 100vw;

            border-bottom: 1px solid var(--color-blue-400);

            content: "";
        }
    }

    ${media.lgUp} {
        flex-direction: row;
        justify-content: space-between;

        :first-of-type {
            padding-bottom: 50px;

            :after {
                left: 10px;

                width: calc(100% - 20px);

                border-bottom-color: var(--color-gray-700);
            }
        }
    }

    ${media.xlUp} {
        align-items: flex-start;
        justify-content: flex-start;
    }
`;
