import styled from "styled-components";

import media from "constants/media";

export const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 0 10px;
    justify-content: center;
    padding: 30px 10px 25px;

    text-align: center;

    border-bottom: 1px solid var(--color-blue-400);

    img:first-of-type {
        margin-left: -5px;
    }

    ${media.mdUp} {
        flex: 1 1 auto;
        justify-content: flex-start;
        max-width: calc(50% - 10px);
        padding: 0;

        text-align: left;

        border-bottom: 0;
    }

    ${media.lgUp} {
        max-width: calc(25% - 15px);
        padding: 0;

        img:first-of-type {
            margin-left: -5px;
        }
    }
`;

export const Title = styled.h2`
    flex: 1 0 100%;
    margin: 0 0 10px;

    color: var(--color-white);
    font-weight: var(--font-weight-medium);
    font-size: 16px;
    font-family: var(--font-family-secondary);
    line-height: 20px;

    ${media.mdUp} {
        margin-bottom: 20px;
    }
`;
