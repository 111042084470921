import { ShipmentContainer, Title, Logo, Text } from "./Shipment.styled";

function Shipment() {
    return (
        <ShipmentContainer>
            <Title>Verzending</Title>
            <Logo
                src="/images/logos/logo-postnl.svg"
                alt="Logo PostNL"
                width={40}
                height={40}
                loading="lazy"
            />
            <Text>Voor 17.00 uur besteld = vandaag verstuurd</Text>
        </ShipmentContainer>
    );
}

export default Shipment;
