import styled from "styled-components";

import media, { sizes } from "constants/media";

export const Container = styled.div`
    position: relative;

    width: 100%;
    margin-bottom: 20px;
    padding: 0 10px;

    ${media.mdUp} {
        width: 100%;
        margin: 0 0 30px;
        padding: 0 0 30px;

        :after {
            position: absolute;
            bottom: 0;
            left: calc(((100vw - ${sizes.md}px + 20px) / 2) * -1);

            width: 100vw;

            border-bottom: 1px solid var(--color-blue-400);

            content: "";
        }
    }

    ${media.lgUp} {
        flex: 1 1 auto;
        max-width: 330px;
        margin: 0;
        padding: 0;

        border-bottom: none;

        :after {
            display: none;
        }
    }

    ${media.xlUp} {
        max-width: calc(${(100 / 12) * 6}% - 10px); /* 6 columns */
    }
`;

export const Title = styled.h2`
    display: none;
    margin: 0 0 20px;

    color: var(--color-white);
    font-weight: var(--font-weight-normal);
    font-size: 20px;
    font-family: var(--font-family-secondary);
    line-height: 30px;

    ${media.mdUp} {
        display: block;
        margin: 0 0 10px;
    }
`;

export const SubTitle = styled.p`
    margin: 0;

    color: var(--color-white);
    font-weight: var(--font-weight-semi-bold);
    font-size: 14px;
    line-height: 30px;

    a {
        color: inherit;
        font-weight: inherit;
    }

    ${media.mdUp} {
        margin: 0 0 5px;
    }
`;

export const Text = styled.p`
    margin: 0;

    color: var(--color-white);
    font-size: 14px;
    line-height: 30px;
`;
