import styled from "styled-components";

import media from "constants/media";

export const Navigation = styled.nav`
    width: 100%;
    padding: 0;

    ${media.lgUp} {
        flex: 1 0 auto;
        max-width: calc(75% - 5px);
    }
`;

export const FooterLinkList = styled.ul`
    display: flex;
    flex-flow: column wrap;
    width: 100%;
    margin: 0;
    padding: 0;

    list-style: none;
    border-bottom: 1px solid var(--color-blue-400);

    ${media.mdUp} {
        gap: 0 20px;
        height: 200px;

        border-bottom: none;
    }
`;

export const Item = styled.li`
    border-top: 1px solid var(--color-blue-400);

    ${media.mdUp} {
        width: calc(${100 / 3}% - 20px);

        border-top: none;
    }
`;

export const FooterLink = styled.a`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5px 0 10px;

    color: var(--color-white);
    font-weight: var(--font-weight-normal);
    font-size: 14px;
    line-height: 50px;

    transition: color var(--animation-duration-default) ease;

    @media (any-hover: hover) {
        &:hover,
        &:focus {
            color: var(--color-gray-300);
        }
    }

    ${media.mdUp} {
        padding: 0;

        line-height: 50px;
    }
`;
