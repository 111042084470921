import PaymentMethods from "../paymentMethods/PaymentMethods";
import Shipment from "../shipment/Shipment";
import Contact from "../contact/Contact";
import Links from "../links/Links";
import Terms from "../terms/Terms";

import { StyledFooter, Row } from "./DesktopFooter.styled";

const navItems = [
    {
        url: "/helpdesk/",
        label: "Veelgestelde Vragen"
    },
    {
        url: "/over-ons/",
        label: "Over ons"
    },
    {
        url: "/wellnesscadeaukaart/",
        label: "Wellness Cadeaukaart"
    },
    {
        url: "/wellnessbon/",
        label: "Wellnessbon"
    },
    {
        url: "/saunabon/",
        label: "Saunabon"
    },
    {
        url: "/sauna-cadeaubon/",
        label: "Sauna Cadeaubon"
    },
    {
        url: "/sauna-cadeaukaart/",
        label: "Sauna Cadeaukaart"
    },
    {
        url: "/nationale-saunabon/",
        label: "Nationale Saunabon"
    },
    {
        url: "/saunabon/",
        label: "Saunabon bestellen"
    },
    {
        url: "https://www.diner-cadeau.nl/",
        label: "Diner Cadeaukaart"
    },
    {
        url: "https://www.cadeaubon.nl/",
        label: "Andere cadeaubonnen"
    }
];

function FooterDesktop() {
    return (
        <StyledFooter>
            <Row>
                <Contact />
                <PaymentMethods />
                <Shipment />
            </Row>
            <Row>
                <Links items={navItems} />
                <Terms />
            </Row>
        </StyledFooter>
    );
}

export default FooterDesktop;
