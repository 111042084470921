import { useEffect, useRef, useState } from "react";

import { scrollToRef } from "helpers/utils";
import { VisuallyHidden } from "theme/mixins";

import IconChevronDown from "components/ui/icons/IconChevronDown";

import Contact from "../contact/Contact";
import Shipment from "../shipment/Shipment";
import PaymentMethods from "../paymentMethods/PaymentMethods";
import Links from "../links/Links";
import Terms from "../terms/Terms";

import { Container, BlobContainer, IconWrapper } from "./MobileFooter.styled";

const navItems = [
    {
        url: "/helpdesk/",
        label: "Veelgestelde vragen"
    },
    {
        url: "/over-ons/",
        label: "Over ons"
    },
    {
        url: "https://www.cadeaubon.nl/",
        label: "Andere cadeaubonnen"
    }
];

function FooterMobile() {
    const [open, setOpen] = useState(false);
    const footerRef = useRef();
    const blobHeight = 38;

    useEffect(() => {
        if (open) {
            scrollToRef(footerRef, blobHeight);
        }
    }, [open]);

    const handleFooter = () => {
        setOpen(!open);
        scrollToRef(footerRef, blobHeight);
    };

    return (
        <Container ref={footerRef} open={open}>
            <BlobContainer onClick={() => handleFooter()}>
                <IconWrapper open={open}>
                    <IconChevronDown
                        size={24}
                        fill="var(--color-white)"
                        aria-hidden="true"
                    />
                    <VisuallyHidden>
                        {open ? "Sluit" : "Open"} footer menu
                    </VisuallyHidden>
                </IconWrapper>
            </BlobContainer>
            <Shipment />
            <Contact />
            <Links items={navItems} />
            <PaymentMethods />
            <Terms />
        </Container>
    );
}

export default FooterMobile;
