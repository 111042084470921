import styled, { css } from "styled-components";

export const Container = styled.footer`
    height: ${({ open }) => (open ? "100%" : "45px")};
    overflow: hidden;

    background-color: var(--color-primary);
    transform-origin: top;

    transition: height 0.3s var(--animation-easing-decelerate);
`;

export const BlobContainer = styled.div`
    position: relative;

    display: flex;
    justify-content: flex-end;
    height: 44px;

    background: url(/images/shapes/footer-bg-mobile.svg) repeat-x 100% 0
        var(--color-white);
    background-size: 768px 44px;

    cursor: pointer;
`;

export const IconWrapper = styled.div`
    position: absolute;
    top: 7px;
    right: 57px;

    transform: rotate(0deg);

    transition: transform 250ms linear;

    ${({ open }) =>
        open &&
        css`
            right: 58px;

            transform: rotate(180deg);
        `}
`;
