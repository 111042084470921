import styled from "styled-components";

import media, { sizes } from "constants/media";

export const Container = styled.div`
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 30px 20px;

    ${media.mdUp} {
        flex-direction: row;
        margin-top: 20px;
        padding: 20px 0;

        :after {
            position: absolute;
            top: 0;
            left: calc(((100vw - ${sizes.md}px + 20px) / 2) * -1);

            width: 100vw;

            border-bottom: 1px solid var(--color-blue-400);

            content: "";
        }
    }

    ${media.lgUp} {
        flex: 1 1 auto;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        max-width: calc(25% + -15px);
        margin-top: 0;
        padding: 0;

        border-top: none;

        :after {
            display: none;
        }
    }
`;

export const Logo = styled.img`
    margin: 0 0 20px;

    ${media.mdUp} {
        width: 187px;
        height: 34px;
        margin: 0;
    }
`;

export const Copyright = styled.div`
    max-width: 260px;
    margin: 0 0 15px;

    color: var(--color-white);
    font-weight: bold;
    font-size: 12px;
    line-height: 30px;

    ${media.mdUp} {
        margin: 15px 0 10px;

        font-weight: var(--font-weight-normal);
        font-size: 13px;
        line-height: 20px;
        text-align: center;
    }

    ${media.lgUp} {
        text-align: left;
    }
`;

export const Policy = styled.ul`
    display: flex;
    gap: 0 30px;
    margin: 0;
    padding: 0;

    text-align: center;

    list-style: none;

    ${media.mdUp} {
        gap: 0 20px;

        text-align: left;
    }

    ${media.lgUp} {
        flex-direction: column;
        gap: 0;
    }
`;

export const PolicyLink = styled.a`
    display: inline-block;
    padding: 5px 0;

    color: var(--color-white);
    font-weight: var(--font-weight-normal);
    font-size: 14px;
    line-height: 20px;
    text-decoration: underline;

    transition: color var(--animation-duration-default) ease;

    ${media.mdUp} {
        padding: 0;

        font-size: 14px;
        line-height: 50px;
        text-decoration: none;

        :hover,
        :focus {
            color: var(--color-gray-300);
        }
    }
`;
