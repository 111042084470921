import PropTypes from "prop-types";

import Head from "components/shared/head/Head";
import Header from "components/shared/pageLayout/header/Header";
import Footer from "components/shared/pageLayout/footer/Footer";

function PageLayout({
    children,
    title,
    description,
    keywords,
    canonical,
    showOrderButton,
    showMobileLogo,
    mobileLogoColor,
    desktopLogoColor,
    buttonColor,
    isChristmas
}) {
    return (
        <>
            <Head
                title={title}
                description={description}
                keywords={keywords}
                canonical={canonical}
            />
            <Header
                showOrderButton={showOrderButton}
                showMobileLogo={showMobileLogo}
                isChristmas={isChristmas}
                mobileLogoColor={mobileLogoColor}
                desktopLogoColor={desktopLogoColor}
                buttonColor={buttonColor}
            />
            {children}
            <Footer />
        </>
    );
}

PageLayout.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
    keywords: PropTypes.arrayOf(PropTypes.string),
    canonical: PropTypes.string,
    showOrderButton: PropTypes.bool,
    showMobileLogo: PropTypes.bool,
    mobileLogoColor: PropTypes.oneOf(["blue", "white", "full-color"]),
    desktopLogoColor: PropTypes.oneOf(["white", "full-color"]),
    buttonColor: PropTypes.oneOf(["blue", "white"]),
    isChristmas: PropTypes.bool
};

PageLayout.defaultProps = {
    title: "Saunabon voor heel Nederland | Sauna & Wellness Cadeaukaart",
    description:
        "Saunabon cadeau geven? Ga dan voor de Sauna & Wellness Cadeaukaart. Met keuze uit meer dan 210 sauna's op mooie locaties door heel Nederland.",
    keywords: ["Sauna Wellness"],
    canonical: undefined,
    showOrderButton: true,
    showMobileLogo: true,
    mobileLogoColor: "full-color",
    desktopLogoColor: "white",
    buttonColor: "blue",
    isChristmas: process.env.featureFlags.christmas
};

export default PageLayout;
