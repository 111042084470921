import Link from "next/link";

import { useMediaQueryContext } from "helpers/hooks/useMediaQueryContext";

import { Container, Logo, Copyright, Policy, PolicyLink } from "./Terms.styled";

function Terms() {
    const { mobileView } = useMediaQueryContext();
    const currentYear = new Date().getFullYear();

    return (
        <Container aria-label="Algemene voorwaarden">
            <Logo
                src="/images/logos/logo-sauna-wellness-white.svg"
                alt="Nationale Sauna & Wellness Cadeaukaart logo"
                width={209}
                height={38}
                loading="lazy"
            />
            <Copyright>
                &copy; {!mobileView && "Nationale "}Sauna & Wellness Cadeaukaart{" "}
                {currentYear}
            </Copyright>
            <Policy>
                <li>
                    <Link
                        href="/algemene-voorwaarden/"
                        passHref
                        prefetch={false}
                    >
                        <PolicyLink>Voorwaarden</PolicyLink>
                    </Link>
                </li>
                <li>
                    <Link href="/privacy" passHref prefetch={false}>
                        <PolicyLink>Privacyverklaring</PolicyLink>
                    </Link>
                </li>
            </Policy>
        </Container>
    );
}

export default Terms;
